<template>
    <header class="header">
        <div class="container">
            <div class="row _nav">
                <div class="col">
                    <div class="d-flex">
                        <a href="/" class="brand d-flex align-items-center">
                            <img src="/theme/img/logo-md.png">
                            <span>IC FLIP</span>
                        </a>
                    </div>
                </div>

                <div class="col-md-auto menu-con offset-1">
                    <div class="row nav-con">
                        <ul class="auth-nav col text-right pxr-0">
                            <li class="nav-item auth-wrap">
                                <a href="#" @click.prevent="connectWallet()" class="btn" v-if="!walletConnet">Connect
                                    Wallet</a>
                                <div v-else class="dropdown">
                                    <a class="dropbtn">
                                        <div class="blnce" @click.prevent="refreshBlance()">
                                            {{ walletBalance }} {{ coin }}
                                        </div>
                                        <i class="ic-user user-ico"></i>
                                    </a>
                                    <div class="dropdown-content">
                                        <router-link  to="/play">Play Game</router-link>
                                        <router-link  to="/account" >My Account</router-link >
                                        <a href="#home"  @click.prevent="disconnectWallet()">DISCONNECT</a>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item dark-mod-con">
                                <div class="mode-toggle" @click="modeToggle()" >
                                    <div class="toggle">
                                        <div id="dark-mode" type="checkbox"></div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    data() {
        return {
            darkMode: false, walletConnet: false,
            walletBalance: 0, coin: 'ICP', walletShown: false,
        };
    },
    mounted() {
        var storedMode = this.helpers.getCookie('themeMode');
        if (storedMode) { this.modeToggle(storedMode) }
        else this.modeToggle(storedMode)
        let self= this

        window.addEventListener('dfinityWalletConnected', async(e) => {
            self.walletShown=false;
            self.walletBalance = await self.artemis.getWalletBalance();
            self.walletConnet =true;
        },false);
        window.addEventListener('fetchWAlletBalance', async(e) => {
            self.walletBalance = await self.artemis.getWalletBalance();
        })
        var walletConnected = localStorage.getItem('dfinityWallet');
        this.artemis.connect(walletConnected).then(data=>{ });
    },   
    emits: {
        themeChange: function (mode) {
            var bodySelect = document.querySelector("body");
            bodySelect.classList.forEach((el, i) => {
                var s = [];
                s = bodySelect.classList[i].match(/(^|\s)theme-\S+/g);
                if (s.length) { bodySelect.classList.remove(bodySelect.classList[i]) }
            });
            bodySelect.classList.add(mode);
            return true;
        }, 
        fetchWAlletBalance:async function(){
            this.walletBalance = await self.artemis.getWalletBalance();

            console.log(this.walletBalance )
            return true;
        }
    },
    methods: {
        refreshBlance:function(){
            window.dispatchEvent(new CustomEvent('dfinityWalletConnected'), 1);
        },
        modeToggle: function (mode = '') {
            if (!mode) {
                var storedMode = this.helpers.getCookie('themeMode');
                console.log(storedMode);
                if (storedMode == 'theme-dark') {
                    storedMode = 'theme-light'
                } else if (storedMode == 'theme-light') {
                    storedMode = 'theme-dark'
                } else {
                    storedMode = 'theme-dark'
                }
                this.helpers.setCookie('themeMode', storedMode);
                // this.$emit("themeChange", storedMode);
                this.themeChange(storedMode)
               
            } else {
                this.helpers.setCookie('themeMode', mode);
                // this.$emit("themeChange", mode);
                this.themeChange(mode)
            }
        },
        connectWallet: async function () {
            window.dispatchEvent(new CustomEvent('trigerwalletPopupOpen'));
          //  this.walletShown = (this.walletShown) ? false : true;
            //this.walletConnet= this.walletConnet?false:true;
        },
        disconnectWallet:async function(){
            await this.artemis.disconnect();
            window.location.reload()
        },
        themeChange: function (mode) {
            var bodySelect = document.querySelector("body");
            bodySelect.classList.forEach((el, i) => {
                var s = [];
                s = bodySelect.classList[i].match(/(^|\s)theme-\S+/g);
                if (s.length) { bodySelect.classList.remove(bodySelect.classList[i]) }
            });
            bodySelect.classList.add(mode);
            return true;
        }
    }
}
</script>