<template>
    <div v-if="gameStatus == 'none'" class="player-box">
        <div class="row mt-2 coin-sides">
            <div class="col-6">
                <div class="c-side" :class="sideSelected == 'head' ? 'selected' : ''" data-coin-side="head"
                    @click="selectSide('head')">
                    <img src="/theme/img/fliphead.png" />
                    <h3>HEADS</h3>
                </div>
            </div>
            <div class="col-6">
                <div class="c-side" :class="sideSelected == 'tail' ? 'selected' : ''" data-coin-side="tail"
                    @click="selectSide('tail')">
                    <img src="/theme/img/fliptail.png" />
                    <h3>TAILS</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 sec-title mt-3">
                <h1>
                  <span v-if="sideSelected && betAmount" class="ft-cap ft-clr-theme1">{{ sideSelected }}S</span>
                  FOR <span v-if="betAmount" class="ft-cap ft-clr-theme1">{{ betAmount }} ICP</span></h1>
            </div>
        </div>
        <div class="row mt-3 bet-amts">
            <div class="col-lg-4 mt-3" v-for="(item, id) in listOfPackages" v-bind:key="id">
                <button class="btn" :data-btn-id="id" @click.prevent="selectBetAmount(item, id)"
                    :class="item == betAmount ? 'selected' : ''">{{ item }} ICP</button>
            </div>
        </div>
        <div class="row mt-5 justify-content-md-center">
            <div class="col-lg-8">
                <p class="warm-msg">{{ warnMsg }}</p>
            </div>
        </div>
        <div class="row mt-2 justify-content-md-center">

            <div class="col-lg-8 bet-confm-holder">
                <button class="btn" @click.prevent="playGame()">Double Or Nothing</button>
            </div>
        </div>
    </div>
    <div v-else-if="gameStatus == 'playing'" class="player-box loading-box">
        <div class="row justify-content-md-center">
            <div class="col-lg-8 loading-con">
                <img src="/theme/img/logo-anim-sp1.gif" />
                <h3 class="mt-3">Playing ...</h3>
                <h2 class="mt-3 ft-clr-theme1">{{ statMsg }}</h2>
                <h3 class="mt-3">{{ gameStatMSg }}</h3>
            </div>
        </div>
    </div>
    <div v-else-if="gameStatus == 'playerResult'" class="player-box loading-box">
        <div class="row mt-5 justify-content-md-center">
            <div class="col-lg-6">
                <div class="player-result">
                    <img v-if="flipResult=='head'" src="/theme/img/fliphead.png" />
                    <img v-else-if="flipResult=='tail'" src="/theme/img/fliptail.png" />
                    <h3 class="ft-cap ft-clr-theme1 mt-4">{{ flipResult }}S</h3>
                    <h4 class="mt-4 result-msg">
                        <p v-if="sideSelected==flipResult">
                            YOU WON {{ betAmount }} {{ coin }}<br/>
                            <span class="mt-2 sucess-clr">CONGRATS</span>
                        </p>
                        <p v-else-if="sideSelected!=flipResult">
                            YOU LOST<br/>
                            <span class="mt-2 error-clr">TRY AGAIN</span>
                        </p>
                    </h4>
                    <div class="w-100 mt-4" v-if="sideSelected==flipResult">
                        <button class="btn reset-game" @click.prevent="resetGame()">PLAY NEW GAME</button>
                    </div>
                    <div class="w-100 mt-4"  v-else-if="sideSelected!=flipResult">
                        <button class="btn reset-game" @click.prevent="resetGame()">FLIP AGAIN </button>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>

const ICFLIP_IDL = ({ IDL }) => {
    const GameId = IDL.Int;
    const FlipResponse = IDL.Int;
    const AccountIdentifier = IDL.Text;
    const Transaction = IDL.Record({
      'amount' : IDL.Nat64,
      'transactionId' : IDL.Text,
    });
    const TransactionError = IDL.Variant({ 'Other' : IDL.Text });
    const TransactionResponse = IDL.Variant({
      'ok' : Transaction,
      'err' : TransactionError,
    });
    const Canister = IDL.Service({
      'createGame' : IDL.Func([], [GameId], []),
      'createGame2' : IDL.Func([IDL.Nat64], [GameId], []),
      'mintNFT' : IDL.Func([IDL.Int, IDL.Nat64, IDL.Int], [FlipResponse], [])
    });
    return Canister;
};
const game_canister_Accnt_ID = "f8892cf99c172d903844164a36d251d76add3cf9ae804b4a1d8e144476a1d1f7"
export default {
    data() {
        return {
            walletConnet: false, coin: ' ICP', sideSelected: '',
            listOfPackages: [0.05 , 0.1, 0.25, 0.50, 1.00, 2.00],
            betAmount: 0, gameStatus: 'none', // node , playing , winHead , winTail ,
            warnMsg: '', statMsg: '', flipResult: '',gameStatMSg:''
        };
    },
    mounted() {

    },
    emits: {

    },
    methods: {
        selectSide: function (side) {
            this.sideSelected = side;
        },
        selectBetAmount: function (amt) {
            this.betAmount = amt;
        },
        playGame: async function () {
            if (this.betAmount && this.sideSelected) {
                this.gameStatus = 'playing',
                this.statMsg = this.sideSelected.toUpperCase()+"S FOR  "+this.betAmount+""+this.coin;
                this.gameStatMSg = 'Requesting Game...';
                var IDL = ICFLIP_IDL;
                var ICFLIP_CANISTER_ID = 'nrq3s-cyaaa-aaaah-aby5a-cai';
                var betAmount =  parseInt(parseFloat(this.betAmount ) * 1e8); 
                var actor = await this.artemis.getCanisterActor(ICFLIP_CANISTER_ID , IDL );
                var gameId = await actor.createGame2(BigInt(betAmount)).catch(err => {
                     console.log("Error creating");console.log(err);reject(false)
                    });
                if(!gameId || gameId <= 0){ this.gameStatMSg ='Unable to Start the game';}
                else{
                    this.gameStatMSg = 'Requesting ICP...';
                    //this.betAmount
                    var ICPTs = betAmount;
                    var blockIndex = await this.artemis.requestICPTransfer({
                        to: game_canister_Accnt_ID,amount: { e8s: ICPTs },fee: { e8s: 10000 },memo: BigInt(gameId),
                        from_subaccount: [],created_at_time:[]
                    });
                    window.dispatchEvent(new CustomEvent('dfinityWalletConnected'), 1);
                    this.gameStatMSg = 'Fliping Coin';
                    var selectedValue = this.sideSelected=='head'?1:0;
                    var flip =  parseInt(await actor.mintNFT(gameId, blockIndex, selectedValue).catch(err => { 
                    //  console.log("Error starting");console.log(err);
                    }));
                    window.dispatchEvent(new CustomEvent('dfinityWalletConnected'), 1);
                    this.flipResult  = flip ==1?'head':'tail'
                    this.gameStatus = 'playerResult'
                    
                    ///console.log("Game Result: ", flip);

                }

                // return new Promise(async (resolve, reject) => {
                //     if (!!gameId) return resolve(gameId);
                //     reject(false)
                // })
            } else {
                this.warnMsg = 'Please Select Side or Bet Amount!'
            }
        },
        resetGame:function(){
            window.dispatchEvent(new CustomEvent('dfinityWalletConnected'), 1);
            this.gameStatus = 'none';
           // this.betAmount =0;
        }
    },
    watch: {
        warnMsg: function () {
            setTimeout(() => { this.warnMsg = "" }, 3000);
        },
        // gameStatus: function () {
        //     if (this.gameStatus == 'playing') {
        //         setTimeout(() => { this.gameStatus = 'playerResult' , this.flipResult = 'head'}, 3000);
        //     }
        // }
    }
}
</script>